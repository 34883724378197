import React, { PropTypes } from 'react';
import nameTitle from '../../images/name.png';
import timeTitle from '../../images/time.png';
import rankTitle from '../../images/rank.png';
import '../../App.css';

const ColumnHeader = ({}) => (
  <div className='row colheader '>
    <div className='col-xs-1 col-rank'>
      <img src={rankTitle} alt='#' className='rank-title' />
    </div>
    <div className='col-xs-5 col-name'>
      <img src={nameTitle} alt='Name' className='name-title' />
    </div>
    <div className='col-xs-3 alltime col-time'>
      <img src={timeTitle} alt='Time' className='time-title' />
    </div>
  </div>
);

// ColumnHeader.propTypes = {
//   onClickAll: PropTypes.func,
// };

export default ColumnHeader;
