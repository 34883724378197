import React from 'react';
import leaderboardTitle from '../../images/leaderboard-title.png';
import '../../App.css';

const LeaderboardHeader = () => {
  return (
    <div className='leadheader'>
      <img
        src={leaderboardTitle}
        alt='TOP 10 LEADERBOARD'
        className='leaderboard-title'
      />
    </div>
  );
};

export default LeaderboardHeader;
