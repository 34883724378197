import LeaderboardHeader from './LeaderboardHeader';
import UserScore from './UserScore';
import ColumnHeader from './ColumnHeader';
import React, { useState, useEffect } from 'react';
import './style.css';
import axios from 'axios';
import { GetLeaderboard } from '../../apiService';
require('dotenv').config();

const Leaderboard = () => {
  const [scoreList, setScoreList] = useState([]);

  useEffect(() => {
    postScoreboard();
  }, []);

  const scoreToTime = (score) => {
    const duration = 2147483647 - score;
    var milliseconds = parseInt(duration % 1000),
      seconds = parseInt((duration / 1000) % 60),
      minutes = parseInt((duration / (1000 * 60)) % 60);

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return minutes + ':' + seconds + '.' + milliseconds;
  };

  const updateList = (list) =>
    list.map((score) => ({
      name: score.DisplayName,
      score: score.StatValue,
      time: scoreToTime(score.StatValue),
    }));

  async function postScoreboard() {
    GetLeaderboard().then((response) => {
      console.log(response.data.Leaderboard);
      const list = updateList(response.data.Leaderboard);
      setScoreList(list);
    });
  }

  useEffect(() => {}, [scoreList]);

  let userlist = scoreList.map((user, i) => (
    <UserScore username={user.name} rank={i + 1} alltime={user.time} />
  ));

  return (
    <div className='leaderboard'>
      <LeaderboardHeader />
      <div className='leaderboard-border'>
        <ColumnHeader />
        {userlist}
      </div>
    </div>
  );
};

export default Leaderboard;
