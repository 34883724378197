import './App.css';
import Unity, { UnityContent, UnityContext } from 'react-unity-webgl';
import Leaderboard from './components/leaderboard/Leaderboard';
import logo from './images/logo.png';
import fullScreenImg from './images/fullscreen-button.png';
import keysImg from './images/keys.png';
import controlsButton from './images/controls-button.png';
import pogoBackImg from './images/pogo-back-img.png';
import Modal from './components/modal/Modal';
import { useState } from 'react';

const unityContent = new UnityContent(
  '../../build/pogomaster_v1.2.json',
  '../../build/UnityLoader.js'
);

function App() {
  const [showModalState, setShowModalState] = useState(false);

  const showModal = () => {
    setShowModalState(!showModalState);
  };

  const onFullScreen = () => {
    unityContent.setFullscreen(true);
  };

  return (
    <div className='App'>
      <header className='app-header'>
        <div className='logo-container'>
          <img src={logo} className='logo' />
        </div>
      </header>
      <div className='unity-loader-container'>
        <div className='unity-loader'>
          <Unity unityContent={unityContent} />
        </div>
      </div>

      <div className='footer-container'>
        <div className='fullscreen-container'>
          <button className='controls-button-container' onClick={() => {}}>
            <img
              className={
                !showModalState ? 'controls-button' : 'controls-button-hold'
              }
              src={controlsButton}
              alt='Controls'
              onClick={() => {
                showModal();
              }}
            />
          </button>
          <button
            className='fullscreen-button-container'
            onClick={onFullScreen}
          >
            <img
              className='fullscreen-button'
              src={fullScreenImg}
              alt='Fullscreen'
              onClick={() => onFullScreen}
            />
          </button>
        </div>
      </div>
      <div className='underfooter-container'></div>
      <div className='modal-container'>
        <Modal onClose={showModal} show={showModalState}>
          <img src={keysImg} alt='keys' className='keys' />
        </Modal>
      </div>

      <Leaderboard />

      <img src={pogoBackImg} alt='' className='pogo-back-img' />
    </div>
  );
}

export default App;
