import React, { PropTypes } from 'react';
import '../../App.css';

const UserScore = ({ rank, username, alltime }) => {
  const checkIfTop = () => {
    if (rank) {
      if (rank === 1) {
        return 'rank-one';
      }
      if (rank === 2) {
        return 'rank-two';
      }
      if (rank === 3) {
        return 'rank-three';
      }
    }
    return '';
  };

  const topRank = checkIfTop();

  return (
    <div className={`row users  vcenter ${topRank}`}>
      <div className='col-xs-1 col-user-rank'>
        <h4>{rank}</h4>
      </div>
      <div className='col-xs-5 col-user-name'>
        <h4>{username}</h4>
      </div>
      <div className='col-xs-3 col-user-time'>
        <h4>{alltime}</h4>
      </div>
    </div>
  );
};

// UserScore.propTypes = {
//   rank: PropTypes.number.isRequired,
//   username: PropTypes.string.isRequired,
//   alltime: PropTypes.number.isRequired,
// };

export default UserScore;
